<template>
    <div class='container'>
      <!--轮播图-->
      <van-swipe :autoplay='2000' indicator-color='black' v-show='0'>
        <van-swipe-item v-for='(item, index) in adImg' :key='index'>
          <van-image :src='item' />
        </van-swipe-item>
      </van-swipe>

      <div class='body'>
        <div >
          <!--目录-->
          <van-sidebar v-model='activeCategory' @change='onCategoryChange'>
            <van-sidebar-item v-for='item in categoryList' :key='item._id' :title='item.name'></van-sidebar-item>
          </van-sidebar>
        </div>

        <!--列表-->
        <van-list v-show='0'>
          <div class='cell' >
            <van-cell size='large' v-for='i in dataList' :key='i._id' :title='i.title' @click='clickItem'></van-cell>
          </div>
        </van-list>

      </div>

      <img :src='userinfo.headimgurl' />
      <p>{{userinfo.nickname}}</p>

      <!--测试按钮-->
      <van-button type='primary' @click='clickLink'>选择图片</van-button>
      <van-button type='primary' @click='openLocation'>打开位置</van-button>
      <van-button type='primary' @click='getLocation'>获取位置</van-button>
      <van-button type='primary' @click='share'>分享</van-button>
    </div>
</template>

<script>
const axios = require('axios')

const appid = 'wx5cbc97417fd64c4a'
const appsecret = 'ac89a43ca0e2e76c8ed32639890e7ecc'

export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      activeCategory: 0,
      categoryList: [],
      adImg: [ // 轮播图url
        'http://h5.hkbuy.xyz/baidu.png',
        'http://h5.hkbuy.xyz/baidu.png',
        'http://h5.hkbuy.xyz/baidu.png'
      ],
      pageNo: 0,
      dataList: [],
      code: '',
      userinfo: {},
      redirecturi: 'http://h5.hkbuy.xyz'
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetchCategory()

    // this.redirecturi = window.location.href.split('#')[0]

    const code = this.$route.query.code
    if (!code) {
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(this.redirecturi)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      window.location.href = url
    } else {
      this.code = code

      this.initConfig()
    }
  },
  mounted () {},
  methods: {
    async initConfig () {
      // this.redirecturi = window.location.href.split('#')[0]

      const url = `https://share.hkbuy.xyz/web/api/wx/jsapi_userinfo?code=${this.code}`
      const ret = await axios.get(url)

      this.userinfo = ret.data
      console.log(ret.data)

      const url5 = window.location.href.split('#')[0]
      // alert(url5)

      const url3 = `https://share.hkbuy.xyz/web/api/wx/jsapi_sign?url=${encodeURIComponent(url5)}`
      const ret3 = await axios.get(url3)
      console.log(ret3.data)

      const configObj = {
        debug: true,
        appId: ret3.data.appId,
        timestamp: ret3.data.timestamp,
        nonceStr: ret3.data.nonceStr,
        signature: ret3.data.signature,
        jsApiList: ['checkJsApi',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'onMenuShareQQ',
          'onMenuShareWeibo',
          'onMenuShareQZone',
          'hideMenuItems',
          'showMenuItems',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem',
          'translateVoice',
          'startRecord',
          'stopRecord',
          'onVoiceRecordEnd',
          'playVoice',
          'onVoicePlayEnd',
          'pauseVoice',
          'stopVoice',
          'uploadVoice',
          'downloadVoice',
          'chooseImage',
          'previewImage',
          'uploadImage',
          'downloadImage',
          'getNetworkType',
          'openLocation',
          'getLocation',
          'hideOptionMenu',
          'showOptionMenu',
          'closeWindow',
          'scanQRCode',
          'chooseWXPay',
          'openProductSpecificView',
          'addCard',
          'chooseCard',
          'openCard'
        ]
      }
      // alert(JSON.stringify(configObj))

      wx.config(configObj)
    },

    async getAccessToken () {
      const url = `https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=${appid}&secret=${appsecret}`
      const ret = await axios.get(url)
      return ret.data
    },

    async fetchCategory () {
      const res = await this.$http.get('/category')
      this.categoryList = res.data
    },

    async onCategoryChange (index) {
      const categoryId = this.categoryList[index]._id
      this.reqCategoryItemList(categoryId, 0)
    },

    async reqCategoryItemList (id, pageNo) {
      const res = await this.$http.get(`/category/${id}`)
      this.dataList = res.data
    },

    clickLink () {
      /* this.$router.push({
        path: '/link',
        query: {
          name: 'abcdef',
          img: 'https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png',
          link: 'https://share.hkbuy.xyz/web/api/dtk'
        }
      }) */
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          console.log(res) // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
        }
      })
    },

    openLocation () {
      wx.openLocation({
        latitude: 0, // 纬度，浮点数，范围为90 ~ -90
        longitude: 0, // 经度，浮点数，范围为180 ~ -180。
        name: '', // 位置名
        address: '', // 地址详情说明
        scale: 1, // 地图缩放级别,整型值,范围从1~28。默认为最大
        infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
      })
    },

    getLocation () {
      wx.getLocation({
        type: 'wgs84', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
        success: function (res) {
          // var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          // var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          // var speed = res.speed; // 速度，以米/每秒计
          // var accuracy = res.accuracy; // 位置精度
          alert(JSON.stringify(res))
        }
      })
    },

    share () {
      wx.updateAppMessageShareData({
        title: '自定义分享表头', // 分享标题
        desc: '分享的内容', // 分享描述
        link: 'https://h5.hkbuy.xyz/about', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: '', // 分享图标
        success: function () {
          // 设置成功
          alert('分享成功')
        }
      })
    },

    clickItem (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped lang="less">
.container {
  background-color: #fff;
}

.body {
  display: flex;
  flex-direction: row;
  background-color: #eee;
}

.cell {
  width: 100%;
  height: 40rpx;
}
</style>
